<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="'导出发货地址'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :lg="12" :md="12" :sm="24" :xs="24">
          <a-form-item label="订单编号">
            <a-input
              v-model:value="where.orderNumber"
              placeholder="请输入订单编号"
              allow-clear
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24" :xs="24">
          <a-form-item label="发货状态:">
            <a-select
              allow-clear
              placeholder="请选择发货状态"
              v-model:value="where.deliveryStatus"
            >
              <a-select-option
                v-for="item in delivery"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24" :xs="24">
          <a-form-item label="订单时间:">
            <a-range-picker
              v-model:value="daterange"
              value-format="YYYY-MM-DD"
              class="ud-fluid"
            >
              <template #suffixIcon>
                <calendar-outlined />
              </template>
            </a-range-picker>
          </a-form-item>
        </a-col>
      </a-row>
      <a-checkbox v-model:checked="checked" @change="checkedChange"
        >导出发货地址并设置为发货状态</a-checkbox
      >
    </a-form>
  </a-modal>
</template>

<script>
import * as dictDataApi from '@/api/sys/dictData'
import * as pointsOrderApi from '@/api/jyyw/pointsOrder'
import regions from '@/utils/regions'
import { toDateString } from '@/utils/util.js'
export default {
  name: 'export-Edit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      logDict: [],
      cityData: regions,
      checked: true,
      // 表格搜索条件
      where: {},
      page: 1,
      limit: 10,
      list: [],
      // 发货状态字典项
      delivery: [],
      // 日期范围选择
      daterange: []
    }
  },
  created() {
    this.queryDeliveryRoles()
  },
  mounted() {},
  watch: {
    daterange() {
      if (this.daterange && this.daterange.length === 2) {
        this.where.createTimeStart = this.daterange[0] + ' ' + '00:00:00'
        this.where.createTimeEnd = this.daterange[1] + ' ' + '23:59:59'
      } else {
        this.where.createTimeStart = null
        this.where.createTimeEnd = null
      }
    }
  },
  methods: {
    checkedChange(e) {
      console.log('e', e.event)
    },
    save() {
      if (this.checked) {
        this.where.bDeliveryStatus = '20'
      } else {
        this.where.bDeliveryStatus = null
      }
      pointsOrderApi
        .exports({
          ...this.where,
          ...this.order,
          page: this.page,
          limit: this.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          const { data, headers } = res
          const fileName =
            '发货地址-' + toDateString(new Date(), 'MMddHHmmss') + '.xlsx'
          const blob = new Blob([data], { type: headers['content-type'] })
          const dom = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(fileName)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)

          this.updateVisible(false)
          this.$emit('done')
        })
        .catch((e) => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
      this.$emit('update:data', this.form)
    },
    // 发货状态
    queryDeliveryRoles() {
      dictDataApi
        .querydictdata('deliveryStatus')
        .then((res) => {
          if (res.code === 0) {
            this.delivery = res.data
            this.where.deliveryStatus = this.delivery[0].dictDataCode
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
